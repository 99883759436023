import './AppointmentEdit.less'

import { Button, Form, Input, Popover } from 'antd'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import {
  AclAction,
  AclFeature,
  Appointment,
  AppointmentColor,
  AppointmentSubject,
  UserConfigKey,
  createAppointment,
  updateAppointment
} from '../../../../requests'
import { maxTitleLengthLong, validateRequired } from '../../../../validation'
import { UserContext } from '../../../auth'
import { DatacFormItem, DatacIcon, DatacMessage, DatacModal, DatacOption, DatacSelect } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { AppointmentEditDateTime } from './AppointmentEditDateTime'
import { AppointmentEditStudy } from './AppointmentEditStudy'
import { AppointmentEditSubjects, defaultCapacity } from './AppointmentEditSubjects'

type FormData = Appointment & { centerId: string }

interface AppointmentEditProps {
  onSubmitted: () => void
  centersOptions: DatacOption[]
}

export const AppointmentEdit: React.VFC<AppointmentEditProps> = ({ onSubmitted, centersOptions }) => {
  const intlAppointment = useScopedIntl('calendar.appointment')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [studyId, setStudyId] = useState<string>(null)
  const [currentSubjects, setCurrentSubjects] = useState<DatacOption[]>([])
  const [currentColor, setCurrentColor] = useState(AppointmentColor.Blue2)
  const { appointmentToEdit, setAppointmentToEdit, userTimezone } = useCalendarStore()
  const { user } = useContext(UserContext)

  useEffect(() => {
    setIsModalOpened(!!appointmentToEdit)
  }, [appointmentToEdit])

  useEffect(() => {
    if (!isModalOpened) return

    const savedCenterId = user.getConfigValue<string>(UserConfigKey.LastAppointmentCenter, null)
    const defaultCenterId = savedCenterId || (centersOptions.length === 1 && centersOptions[0]?.value)

    if (appointmentToEdit) {
      formInstance.setFieldsValue({
        title: appointmentToEdit.title,
        publicTitle: appointmentToEdit.publicTitle,
        startDate: appointmentToEdit.startDate,
        startTime: appointmentToEdit.startTime,
        endTime: appointmentToEdit.endTime,
        capacity: appointmentToEdit.capacity || defaultCapacity,
        centerId: appointmentToEdit.center?.id || defaultCenterId,
        studyId: appointmentToEdit.study?.uuid,
        timezone: appointmentToEdit.timezone
      })
      setCurrentSubjects(
        appointmentToEdit.subjects.map((s: AppointmentSubject) => ({
          value: s.id,
          label: `${s.fullName || ''} (${s.id})`,
          sublabel: s.email
        }))
      )
      setStudyId(appointmentToEdit.study?.uuid)
      setCurrentColor(appointmentToEdit.color)
      return
    }

    formInstance.resetFields()
    formInstance.setFieldsValue({ capacity: defaultCapacity, centerId: defaultCenterId })
    setCurrentColor(AppointmentColor.Blue2)
    setCurrentSubjects([])
    setStudyId(null)
  }, [isModalOpened])

  const onSelectCenter = (centerId: string) => {
    user.setConfigValue(UserConfigKey.LastAppointmentCenter, centerId)
  }

  const onSubmit = (data: FormData) => {
    if (currentSubjects.length > data.capacity) return
    ;(appointmentToEdit?.id ? updateAppointment : createAppointment)(
      {
        ...data,
        subjects: currentSubjects.map(s => ({ id: s.value })),
        study: { uuid: studyId },
        center: { id: data.centerId },
        color: currentColor,
        id: appointmentToEdit?.id || undefined,
        timezone: data.timezone || appointmentToEdit?.timezone || userTimezone
      },
      {
        onSuccess: () => {
          onModalClose()
          onSubmitted()
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const colorPopoverContent = (
    <div className="appointment-edit__modal__color-picker__content">
      {Object.values(AppointmentColor).map(color => (
        <div
          key={color}
          className={classNames(
            'appointment-edit__modal__color-picker__color',
            `calendar-content__background--${color}`
          )}
          onClick={() => setCurrentColor(color)}
        />
      ))}
    </div>
  )

  const onModalClose = () => {
    setIsModalOpened(false)
    setAppointmentToEdit(null)
  }

  return (
    <>
      {user.canDo(AclFeature.Calendar)(AclAction.Add) && (
        <Button className="appointment-edit__button" type="primary" onClick={() => setIsModalOpened(true)} size="large">
          <DatacIcon name="plus" type="white" size="big" /> {intlAppointment('title')}
        </Button>
      )}
      <DatacModal
        className="appointment-edit__modal"
        isOpened={isModalOpened}
        onClose={onModalClose}
        onSubmit={() => formInstance.submit()}
        title={intlAppointment(appointmentToEdit?.id ? 'update' : 'create')}
        submitLabel={intlAppointment(appointmentToEdit?.id ? 'update' : 'create')}
        destroyOnClose
        footerOption={
          <Popover trigger="click" placement="top" content={colorPopoverContent}>
            <div className="appointment-edit__modal__color-picker">
              <div
                className={classNames(
                  'appointment-edit__modal__color-picker__color',
                  `calendar-content__background--${currentColor}`
                )}
              />
              <DatacIcon name="chevronDown" raw />
            </div>
          </Popover>
        }
      >
        <Form form={formInstance} name="NewEventForm" onFinish={onSubmit}>
          <DatacFormItem
            label={intlAppointment('title.label')}
            showAsterisk
            name="title"
            validate={validateRequired(intl('common.required'))}
            icon="alignLeft"
          >
            <Input size="large" placeholder={intlAppointment('title.placeholder')} maxLength={maxTitleLengthLong} />
          </DatacFormItem>

          <DatacFormItem
            label={intlAppointment('public.title.label')}
            description={intlAppointment('public.title.description')}
            name="publicTitle"
            icon="alertCircle"
            collapsible
            collapsed
          >
            <Input
              size="large"
              placeholder={intlAppointment('public.title.placeholder')}
              maxLength={maxTitleLengthLong}
            />
          </DatacFormItem>

          <AppointmentEditDateTime isVisible={isModalOpened} formInstance={formInstance} />

          <AppointmentEditSubjects
            isVisible={isModalOpened}
            formInstance={formInstance}
            currentSubjects={currentSubjects}
            setCurrentSubjects={setCurrentSubjects}
          />

          <AppointmentEditStudy studyId={studyId} setStudyId={setStudyId} />

          <DatacFormItem
            label={intlAppointment('location.label')}
            name="centerId"
            icon="mapPin"
            showAsterisk
            validate={validateRequired(intl('common.required'))}
          >
            <DatacSelect
              showSearch
              options={centersOptions}
              placeholder={intlAppointment('location.placeholder')}
              onChange={onSelectCenter}
            />
          </DatacFormItem>
        </Form>
      </DatacModal>
    </>
  )
}
